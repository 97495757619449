@import '~semantic-ui-css/semantic.min.css';
@import '~react-image-gallery/styles/css/image-gallery.css';

.Alexslidercontainer {
    position: relative;
}

.Alexslider {
    position: absolute;
    top:50%;
    margin-top:-0.5em;
    color: #0000004d;
}

.Alexslider.right {
    right: 0px;
}

.image-gallery-content.fullscreen .image-gallery-slide img {
    width: auto;
    height: 100vh;
}

.image-gallery-image {
    text-align: center;
}

.image-gallery-slide img {
    width: auto;
    height: calc(100vh - 105px);
}

.image-gallery-slide {
    background: none;
}

.ui.inverted.header .sub.header {
    color: #6b0c25;
}

.route-wrapper {
  position: relative;
}

.route-wrapper > div {
  position: absolute;
}

.alexfeedcard .ui.image {
    width: 100%;
}

/*.leftMenu {
    border-color: #ffffffb3;
    border-width: 2px;
    border-style: solid;
}*/
a.ui.corner.label > i.icon {
    cursor: pointer;
}

.ui.secondary.inverted.menu .active.item {
    background-image: linear-gradient(90deg, rgba(255,255,255,0), rgba(255,255,255,0.12), rgba(255,255,255,0)) !important;
    background: none;
}
